import { reactiveComputed } from '@vueuse/core'

export { useCurrencyQuerier }
export type { ICurrencyInfo }

interface ICurrencyInfo {
  code: string
  symbol: string
  icon: string
  shortcut: Array<number>
  balance: number
  minValue: number
  maxValue: number
  to: Record<string, number>
}

function useCurrencyQuerier() {
  const { config } = useAppState()
  const { account } = tronComp.useAccountCurrent()

  return reactiveComputed(() => {
    const exchange = unref(config)?.exchange
    const balance = unref(account).balance
    const ratio = exchange?.exchange_profit_ratio ?? 0.1
    const usdtPriceInTrx = unref(config)?.base.usdt_price_in_trx ?? 13
    const currencyList: ICurrencyInfo[] = []
    currencyList.push({
      code: 'tron-trx',
      symbol: 'TRX',
      icon: 'i-cryptocurrency-color:trx',
      shortcut: [exchange?.trx_min_exchange_amount ?? 200, 1000, 2000, 3000],
      balance: balance?.trx ?? 0,
      minValue: exchange?.trx_min_exchange_amount ?? 200,
      maxValue: Number.POSITIVE_INFINITY,
      to: {
        'tron-usdt': 1 / (usdtPriceInTrx + usdtPriceInTrx * ratio),
      },
    })
    currencyList.push({
      code: 'tron-usdt',
      symbol: 'USDT',
      icon: 'i-cryptocurrency-color:usdt',
      shortcut: [exchange?.usdt_min_exchange_amount ?? 10, 50, 100, 150],
      balance: balance?.usdt ?? 0,
      minValue: exchange?.usdt_min_exchange_amount ?? 10,
      maxValue: Number.POSITIVE_INFINITY,
      to: {
        'tron-trx': usdtPriceInTrx - usdtPriceInTrx * ratio,
      },
    })
    return currencyList
  })
}
