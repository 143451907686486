import { createMachine } from 'xstate'

export type { IContext }

interface IContext {
  payAddress: string
  payAmount?: number
  paySymbol?: string
  exchangeAmount: number
  exchangeSymbol?: string
  receiveAddress: string
  energySurplus?: number
  trxSurplus?: number
  bandwidthSurplus?: number
  preBuyTrx?: boolean
}
export { machine }
const machine = createMachine({
  id: 'Swap',
  initial: 'idle',
  states: {
    'idle': {
      on: {
        CONFIRM: [
          {
            target: 'confirming',
            actions: {
              type: 'setInfo',
            },
          },
          {
            target: 'confirming',
          },
        ],
      },
    },
    'confirming': {
      on: {
        PAY: [
          {
            target: 'pre-buy-trx',
            cond: 'preBuy',
          },
          {
            target: 'pre-submitting',
          },
        ],
        CLOSE: {
          target: 'idle',
        },
      },
    },
    'pre-buy-trx': {
      invoke: {
        onDone: {
          target: 'paying',
        },
        onError: {
          target: 'confirming',
        },
        src: 'preBuyTrx',
      },
      tags: ['requesting', 'confirming'],
    },
    'pre-submitting': {
      invoke: {
        id: 'pre-submit',
        onDone: {
          target: 'paying',
        },
        onError: {
          target: 'confirming',
          actions: {
            type: 'showErrorMessage',
          },
        },
        src: 'preSubmit',
      },
      tags: ['requesting', 'confirming'],
    },
    'paying': {
      invoke: {
        id: 'pay',
        onDone: [
          {
            target: 'buy-trx',
            cond: 'preBuy',
          },
          {
            target: 'order-creating',
          },
        ],
        onError: {
          target: 'confirming',
          actions: {
            type: 'showTronErrorMessage',
          },
        },
        src: 'pay',
      },
      tags: ['requesting', 'confirming'],
    },
    'buy-trx': {
      invoke: {
        onDone: {
          target: 'successful',
        },
        onError: {
          target: 'confirming',
        },
        src: 'buyTrx',
      },
      tags: ['requesting', 'confirming'],
    },
    'order-creating': {
      invoke: {
        id: 'create-order',
        onDone: {
          target: 'order-querying',
        },
        onError: {
          target: 'confirming',
          actions: {
            type: 'showErrorMessage',
          },
        },
        src: 'createOrder',
      },
      tags: ['requesting', 'confirming'],
    },
    'order-querying': {
      invoke: {
        id: 'query-order',
        onDone: {
          target: 'successful',
        },
        onError: {
          target: 'confirming',
          actions: {
            type: 'showErrorMessage',
          },
        },
        src: 'queryOrder',
      },
      tags: ['requesting', 'confirming'],
    },
    'successful': {
      on: {
        OK: {
          target: 'idle',
        },
      },
    },
  },
  context: ({} as unknown) as IContext,
  schema: {
    events: {} as ({ type: 'CONFIRM' } & IContext) | { type: 'CLOSE' } | { type: 'PAY' } | { type: 'OK' },
  },
  predictableActionArguments: true,
  preserveActionOrder: true,
  tsTypes: {} as unknown as import('./swapMachine.typegen').Typegen0,

})
