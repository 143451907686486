<script setup lang="ts">
import { watchDeep } from '@vueuse/core'
import { useProvideSwap } from '../hooks/useSwap'
import DappPay from './DappPay.vue'
import TransferPay from './TransferPay.vue'
import TRXBuy from './TRXBuy.vue'
import { useSwapShow } from '~/composables/app/useSwapShow'

useProvideSwap()
const { account } = tronComp.useAccountCurrent()
const { isNotActivated, isItEnabledPre } = useSwapShow()

const exchangeType = ref('TransferPay')
const comp = computed(() => {
  return {
    DappPay,
    TransferPay,
    TRXBuy,
  }[unref(exchangeType)]!
})

function onUpdate(val: string) {
  exchangeType.value = val
}

watchDeep(account, () => {
  if (isNotActivated.value && isItEnabledPre.value)
    exchangeType.value = 'TRXBuy'
}, { immediate: true })
</script>

<template>
  <div class="function-area">
    <div class="function-area-container gradient-border-box">
      <header>
        <h1>
          <span v-if="exchangeType === 'DappPay'">{{ $t("WlsyfjewuEycp3UMK4v5h") }}</span>
          <span v-if="exchangeType === 'TransferPay'">{{ $t("1WcW8FO4NljK9a9oX5Y1G") }}</span>
          <span v-if="exchangeType === 'TRXBuy'">{{ $t('nCh3QNuEnQod6xPEkY5hK') }}</span>
        </h1>
        <ARadioGroup
          v-model:value="exchangeType"
          buttonStyle="solid"
          size="small"
          class="ant-cover__basic-radio !grid"
          :class="isItEnabledPre ? 'grid-cols-3' : 'grid-cols-2'"
        >
          <ARadioButton value="TransferPay" class="lt-mobile:flex-[1_1_0%]">
            {{
              $t("1WcW8FO4NljK9a9oX5Y1G")
            }}
          </ARadioButton>
          <ARadioButton value="DappPay" class="lt-mobile:flex-[1_1_0%]">
            {{
              $t("WlsyfjewuEycp3UMK4v5h")
            }}
          </ARadioButton>
          <ARadioButton v-if="isItEnabledPre" value="TRXBuy" class="lt-mobile:flex-[1_1_0%]">
            {{ $t('nCh3QNuEnQod6xPEkY5hK') }}
          </ARadioButton>
        </ARadioGroup>
      </header>
      <component :is="comp" v-model:exchangeType="exchangeType" :isNotActivated="isNotActivated" @onUpdate="onUpdate" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.function-area {
  @media bp-lt-laptop {
    width: 100%;
  }

  .function-area-container {
    padding: 30px;
    background-color: #222e56;
    border: 3px solid transparent;
    border-radius: 25px;

    @media bp-lt-tabletl {
      padding: 0 0 16px;
      border-radius: 16px;

      & > header {
        --uno: 'pt-16px px-16px grid text-center justify-stretch mb-10px';

        border-radius: 16px 16px 0 0 ;

        & > h1 {
          margin-bottom: 10px;
          font-size: 26px;
        }
      }

      & > .transfer-pay{
        --uno: 'px-16px';
      }

      :deep(.ant-radio-group) {
        height: 34px;
      }
    }

    & > header {
      --uno: 'lt-mobile:grid lt-mobile:text-center lt-mobile:justify-stretch lt-mobile:mb-10px';

      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
    }

    & > .switch-currency-button {
      width: 30px;
      height: 30px;
      margin: 20px 10px;
      cursor: pointer;
    }
  }

  .ant-radio-group{
    >label{
      height: 34px !important;
    }
  }
}
</style>
