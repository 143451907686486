<script setup lang="ts">
import { useProvideSwap } from '../hooks/useSwap'

const { config } = useAppState()
const { fromCrypto, toCrypto } = useProvideSwap()
</script>

<template>
  <div class="about">
    <div class="about-container">
      <header>
        <h1>{{ $t("dDSX1YB6Wm_1Wj0tgwiDA") }}</h1>
        <h2 class="text-[#e2e8f2] mt-10px text-48px font-600">{{ $t('3SqqrvEaZvU4i3LWvARln') }}</h2>
      </header>
      <div class="introduce">
        <ul>
          <li>{{ $t('YP1wlSy8QZ71oHeXZimfC') }}</li>
          <li>{{ $t('UQzG1-N-CK_AqMZ6hzXHg') }}</li>
          <li>{{ $t('bdqJOdv4E_6mPjjm3xsia') }}</li>
          <li>
            {{ $t('NL7dIntCT2Z--6Ye4coZh', {
              num1: config?.exchange.usdt_min_exchange_amount,
              num2: config?.exchange.trx_min_exchange_amount,
            }) }}
          </li>
          <li>
            {{ $t('0m2GvZT7HHq0dcO2crDGB') }}
          </li>
          <li>
            {{ $t('TJBegKZN0USJSixEEbl2N') }}
          </li>
        </ul>
      </div>
      <div class="convert-info">
        <ul>
          <li class="usdt-to-trx">
            <span>1 USDT {{ $t('oQJ8d5Rll9Vyu5-Jmb7Gg') }}</span>&nbsp;
            <span>
              {{ formatAmount(fromCrypto?.info?.to?.['tron-trx'], 6) }}
            </span>&nbsp;
            <span>TRX</span>
          </li>
          <li class="trx-to-usdt">
            <span>1 TRX {{ $t('oQJ8d5Rll9Vyu5-Jmb7Gg') }}</span>&nbsp;
            <span>{{ formatAmount(toCrypto?.info?.to?.['tron-usdt'], 6) }}</span>&nbsp;
            <span>USDT</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.about {
  --uno: 'w-[630px] lt-desktop:w-full lt-desktop:h-full lt-desktop:mb-[30px]';

  .about-container {
    --uno: 'pl-[0] pr-[0] mt-60px lt-mobilel:hidden';

    header {
      .spaceman-img {
        --uno: 'w-327px h-109px relative left--20px';
      }

      h1 {
        --uno: 'mb-[22px] font-800 text-90px lh-7.3rem text-transparent';

        position: relative;
        background: linear-gradient(90deg, rgb(38 128 235 / 100%) 0%, rgb(0 193 165 / 100%) 30%);
        background-clip: text;
      }

      h2 {
        --uno: 'text-48px font-600';
      }
    }

    .introduce {
      --uno: ' mx-[0] my-[23px]';

      & > img {
        --uno: 'w-[40px] h-[40px] absolute right-[0] bottom-[0] lt-tabletl:hideen';
      }
    }

    .convert-info {
      --uno: 'text-[#8098bf]  pt-[26px] relative';
    }

    .convert-info::before {
      --uno: 'absolute top-0 left-10px w-340px h-1px';

      content: '';
      background: linear-gradient(to right, #2a47ab, #2a47ab, transparent 50%) repeat-x;
      background-size: 8px 1px;
    }

    ul {
      --uno: 'list-initial inline list-none text-[#8098bf] leading-[2em]';

      list-style-type: none;

      li {
        padding-left: 10px;
        margin-left: 30px;
        font-size: 20px;
        list-style: url('@/assets/imgs/dot.svg') outside;
      }
    }
  }
}
</style>
