import { createInjectionState, reactiveComputed } from '@vueuse/core'
import { useMachine } from '@xstate/vue'
import { actions } from 'xstate'
import { machine as swapMachine } from '~/pages-components/swap/machine/swapMachine'
import { TronChainId, tron } from '~/utils/tron-v2'
import { formatTronErrorMessage } from '~/utils/tron-v2/tronError'
import { extractContentInBrackets, sleep } from '~/utils'
import { toNum } from '~/utils/math'

/**
 * @description 闪兑状态机
 */
export const [useSwapMachineProvider, useSwapMachine] = createInjectionState(
  () => {
    const { config } = useAppState()

    const machine = useMachine(swapMachine, {
      actions: {
        setInfo: actions.assign((ctx, ev) => {
          if (ev.type === 'CONFIRM') {
            Reflect.deleteProperty(ev, 'type')
            return { ...ev }
          }
          return {}
        }),
        showErrorMessage(ctx, ev) {
          const error = ev.data
          if (error instanceof Error) {
            if (error.message.includes('balance is not sufficient'))
              aMessage.error($t('tm41OUsNwS4B7IkB1jey'))
            else if (error.message.includes('does not exist'))
              aMessage.error(`${extractContentInBrackets(error.message)}  ${$t('AUdgXSOOMymyhIWK9Mg1A')}`)
            else if (error.message.includes('result error: Account resource insufficient error.'))
              aMessage.error($t('necasD5ndOJvyEQtNhj22')) //
            else
              aMessage.error(error.message)
          }
        },
        showTronErrorMessage(ctx, ev) {
          const error = ev.data
          if (error instanceof Error)
            aMessage.error(error.message)
        },
      },
      services: {
        // 正常请求购买TRX流程
        preSubmit: async (ctx) => {
          const res = await apis.exchangePreSubmit(
            {
              requestBody: {
                exchange_symbol: ctx.exchangeSymbol,
                pay_amount: ctx.payAmount,
                pay_symbol: ctx.paySymbol,
                pay_address: ctx.payAddress, // 接收地址
              },
            },
            { errorMessageMode: 'none' },
          )
          // 本次交易不消耗能量
          if (res.energy_used === 0)
            return ctx.trxSurplus
          // 需要消耗的能量 - 账号能量余额 = 实际扣除的能量
          const actualEnergy = res.energy_used - ctx.energySurplus < 0 ? 0 : res.energy_used - ctx.energySurplus
          // 剩余trx - 原价trx = 减掉后剩余的trx
          const energyTrxSurplus = ctx.trxSurplus - Number((actualEnergy * (config.value?.estimate.energy_price ?? 210)) / 1000000)

          // 剩余trx < 实际要消耗的trx
          if (ctx.energySurplus < res.energy_used && energyTrxSurplus < 0)
            throw new Error($t('necasD5ndOJvyEQtNhj22'))

          return energyTrxSurplus
        },
        // 预请求购买TRX
        preBuyTrx: async (ctx) => {
          console.log('ctx.exchangeAmount', ctx.exchangeAmount)
          const trx_amount = toNum(ctx.exchangeAmount.toFixed(6))
          const res = await apis.apiExchangePreBuyTrx({
            requestBody: {
              pay_address: ctx.payAddress as string,
              receive_address: ctx.receiveAddress,
              trx_amount,
            },
          })
          return res
        },
        // 拉起签名
        pay: async (ctx, ev) => {
          try {
            // 购买trx
            if (ctx.preBuyTrx) {
              const signed_txn = await tron.UTTSign(ev.data.signed_txn)
              return {
                order_no: ev.data.order_no,
                pay_tx_id: ev.data.pay_tx_id,
                signed_txn,
              }
            }
            else {
              const formData = {
                fromAddress: ctx.payAddress,
                toAddress: config.value!.wallet.payment_exchange_address,
                chainId: env.prod ? TronChainId.Main : TronChainId.Nile,
                symbol: ctx.paySymbol,
                amount: ctx.payAmount.toFixed(6),
                bandwidthSurplus: ctx.bandwidthSurplus,
                trxSurplus: ctx.trxSurplus,
                energyTrxSurplus: ev.data as number,
              }
              return await tron.transfer(formData)
            }
          }
          catch (e) {
            const msg = formatTronErrorMessage(e)
            throw new Error(msg)
          }
        },
        buyTrx: async (ctx, ev) => {
          return await apis.apiExchangeBuyTrx({ requestBody: ev.data })
        },
        createOrder: async (ctx, ev) => {
          return await apis.exchangeCreateOrder({
            requestBody: {
              exchange_symbol: ctx.exchangeSymbol,
              pay_symbol: ctx.paySymbol,
              receive_address: ctx.receiveAddress,
              signed_txn: ev.data as any,
            },
          }, { errorMessageMode: 'none' })
        },
        queryOrder: async (ctx, ev) => {
          const { data } = ev
          while (true) {
            await sleep(2000)
            try {
              await apis.exchangeQuery(
                {
                  order_no: data!.order_no,
                  trx_address: ctx.payAddress,
                  tx_id: data!.pay_tx_id,
                },
                { errorMessageMode: 'none' },
              )
              break
            }
            catch (e) {
              if (e.code === 12002)
                throw new Error($t('-MjYAqdGMw1Jpq1bk-qXy'))

              if (e.code !== 17019)
                throw new Error($t('vf0ZqDAvmnl30Yap23ntc'))
            }
          }
        },

      },
      guards: {
        preBuy: ctx => !!ctx.preBuyTrx,
      },
      delays: {},
    })
    const states = reactiveComputed(() => {
      const s = machine.state.value
      const confirming = s.matches('confirming')
      const requesting = s.hasTag('requesting')
      const successful = s.matches('successful')
      const canClose = s.can('CLOSE')

      return { confirming, requesting, successful, canClose }
    })

    return { machine, states }
  },
)
