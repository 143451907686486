<script lang="ts">
import { refDebounced, useSessionStorage, useUrlSearchParams } from '@vueuse/core'
import { useQuery } from '@tanstack/vue-query'
import { tryOnMounted } from '@vueuse/shared'
import { useSwapMachineProvider } from '../machine/useSwapMachine'
import ConfirmPayInfo from './ConfirmPayInfo.vue'
import { tron, tronErrors } from '~/utils/tron-v2'
import { useBandwidthSurplus } from '~/composables/tron/useBandwidthSurplus'
import useHideFormHelp from '~/composables/useHideFormHelp'
import { formatTronErrorMessage } from '~/utils/tron-v2/tronError'
import { useSwapShow } from '~/composables/app/useSwapShow'
</script>

<script setup lang="ts">
function useReceiveAddress() {
  const address = ref('')
  const custom = ref(false)
  return reactive({ value: address, custom })
}

const { account, refetch, isConnected } = tronComp.useAccountCurrent()
const { config } = useAppState()
const { notActivated } = useSwapShow()
function shortcutInput(val: number) {
  trxCrypto.value = val
  swapForm.$form.value?.clearValidate()
}
const receiveAddress = useReceiveAddress()
const { machine } = useSwapMachineProvider()

const shortcutMap = computed(() => [(config.value?.exchange.buy_trx_min_amount ?? 10), 50, 100, 200])
const enabled = computed(() => !!trxCrypto && trxCrypto.value >= buyTrxMinAmount.value && isConnected)
const payAmount = computed(() => queryBuyUsdt.data?.value?.pay_amount ?? 0)
const data = computed(() => queryBuyUsdt.data?.value ?? {})
const buyTrxMinAmount = computed(() => config.value?.exchange.buy_trx_min_amount ?? 0)
const trxCrypto = ref(buyTrxMinAmount.value)
const debounced = refDebounced(trxCrypto, 1000)
const params = useUrlSearchParams('history')

const swapForm = useAntFormValidator({
  trxCrypto: {
    value: trxCrypto,
    validator(rule, value) {
      if (value < buyTrxMinAmount.value)
        return Promise.reject(`${$t('cEmfZeyxDlkTkp9W-WoV-')} ${buyTrxMinAmount.value} USDT`)

      return Promise.resolve()
    },
  },
  receiveAddress: {
    value: toRef(receiveAddress, 'value'),
    validator(rule, value) {
      if (value && !tron.isAddress(value))
        return Promise.reject($t('sKnanx9S2n_2cIc8xWvu1'))

      return Promise.resolve()
    },
  },
})
const { target } = useHideFormHelp(swapForm.$form)

const queryBuyUsdt = useQuery({
  queryKey: [apis.apiExchangeQueryBuyTrx.id, debounced, () => account.address],
  enabled,
  retry: false,
  refetchOnWindowFocus: false,
  queryFn: () => apis.apiExchangeQueryBuyTrx({ requestBody: {
    pay_address: account.address,
    receive_address: account.address,
    trx_amount: trxCrypto.value,
  } }),
})

machine.service.onTransition((state) => {
  // 支付成功时清空表单
  if (state.value === 'successful')
    refetch()
})

function onValidatePower() {
  const info = reactive({ isOwnerPermission: false })
  const {
    owner_permission: ownerPermission,
    active_permission: activePermission,
  } = account

  info.isOwnerPermission = false
  if (!ownerPermission || !activePermission)
    info.isOwnerPermission = false

  if (ownerPermission?.threshold > 1)
    info.isOwnerPermission = ownerPermission.keys.some((i: any) => (i.address === tron.tronWeb?.address.toHex(account.address) && i.weight === ownerPermission.threshold))

  else if (ownerPermission?.threshold === 1)
    info.isOwnerPermission = ownerPermission.keys.some((i: any) => (i.address === tron.tronWeb?.address.toHex(account.address)))

  if (!info.isOwnerPermission)
    throw tronErrors.MULTISIGN_NOT_ACTIVATED()
}

async function submit() {
  try {
    refetch()
    if (!toValue(notActivated))
      onValidatePower()
    if ((toValue(account).balance?.usdt ?? 0) < payAmount.value)
      throw tronErrors.USDT_INSUFFICIENT_ERROR()

    machine.send({
      type: 'CONFIRM',
      payAddress: account.address,
      payAmount: toValue(payAmount),
      paySymbol: 'USDT',
      exchangeAmount: trxCrypto.value!,
      exchangeSymbol: 'TRX',
      receiveAddress: account.address,
      energySurplus: (account?.resource?.EnergyLimit ?? 0) - (account?.resource?.EnergyUsed ?? 0), // 当前剩余能量
      trxSurplus: (account?.balanceTrx ?? 0) / 1000000, // 当前剩余TRX
      bandwidthSurplus: unref(useBandwidthSurplus), // 当前剩余带宽
      preBuyTrx: true, // 是否需要走预请求购买TRX
    })
  }
  catch (error) {
    console.log('error', error)
    if (error instanceof Error) {
      const msg = formatTronErrorMessage(error)
      aMessage.error(msg)
    }
  }
}

const isBitPie = useSessionStorage('IS_BITPIE', 0)
tryOnMounted(() => {
  if (isBitPie.value === 1)
    aMessage.info($t('v3h7PL2tPhAkvi4ltAgdf'))
})
</script>

<template>
  <AForm v-bind="swapForm.formProps" class="antd-cover__bubble-help-form">
    <div class="dapp-pay text-[14px]">
      <template v-if="account.address">
        <div class="wallet-address">
          <i class="i-local:trx text-[20px] mr-[10px]" />
          <span>{{ account.address }}</span>
        </div>
      </template>
      <div class="from-currency">
        <div class="input">
          <AFormItem v-bind="swapForm.formItemProps.trxCrypto">
            <AInputNumber
              v-model:value="trxCrypto"
              v-bind="inputMoney"
              :bordered="false"
              :controls="false"
              :placeholder="$t('Z3dE8F8H0GPEYq_juWdf5')"
              :min="buyTrxMinAmount"
              :precision="6"
            >
              <template #addonAfter>
                <i
                  v-if="!!trxCrypto"
                  class="i-ant-design:close-circle-outlined input-clear"
                  @click="trxCrypto = 0"
                />
              </template>
            </AInputNumber>
          </AFormItem>
        </div>
      </div>
      <div class="shortcut-select mb-4.5">
        <div
          v-for="(value, key) of shortcutMap"
          :key="key"
          class="shortcut-select-value"
          data-test-id="qeds"
          @click="shortcutInput(value)"
        >
          {{ value }}
        </div>
      </div>

      <div class="mb-4.5">
        <p class="text-lg mb-2">{{ $t('QWNBNFCpECWKnkEbwzClt') }}</p>
        <ASpin :spinning="toValue(queryBuyUsdt.isFetching)">
          <div class="pay">
            <div v-if="account.balance?.usdt > 0">
              <span>{{ $t('smEjB4VMqYa0LqPYmFjcC') }}</span>
              <span>{{ account.balance?.usdt ?? 0 }} USDT</span>
            </div>
            <div v-if="(data?.active_account_fee_usdt ?? 0) > 0">
              <span>{{ $t('6WloRtBKd2dgpzdD31Xlf') }}</span>
              <span>{{ data?.active_account_fee_usdt }} USDT</span>
            </div>
            <div v-if="(data?.buy_bandwidth_fee_usdt ?? 0) > 0">
              <span>{{ $t('evitwSezNwWsjLcLzVciX') }}</span>
              <span>{{ data?.buy_bandwidth_fee_usdt }} USDT</span>
            </div>
            <div v-if="(data?.buy_energy_fee_usdt ?? 0) > 0">
              <span>{{ $t('bZhvEP4Vy6QksNmUnL') }}</span>
              <span>{{ data?.buy_energy_fee_usdt }} USDT</span>
            </div>
            <div v-if="(data?.exchange_amount ?? 0) > 0">
              <span>{{ $t('h0nrnUa2LpykGorSxEtH4') }}</span>
              <span class="font-bold !text-[#27c1a5] mx-2 text-[20px]">{{ formatAmount(data?.exchange_amount, 6) }} TRX</span>
            </div>
            <div>
              <span>{{ $t('g5wiOEwimiMztyEnAfd5v') }}</span>
              <span class="font-bold !text-[#27c1a5] mx-2 text-[20px]">{{ payAmount }} USDT</span>
            </div>
          </div>
        </ASpin>
      </div>

      <div class="text-sm text-primary mb-2.5">
        <span>{{ $t('J8Lz60Z8cgslm2KfjjXRd') }}</span>
      </div>
      <AButton
        v-if="account.address"
        ref="target"
        class="w-[100%] comfirm-btn"
        data-test-id="792z"
        :disabled="toValue(queryBuyUsdt.isFetching) || payAmount === 0"
        @click="submit"
      >
        {{ $t("oQJ8d5Rll9Vyu5-Jmb7Gg") }}
      </AButton>

      <AButton
        v-else
        class="w-[100%] comfirm-btn"
        data-test-id="ejny"
        @click="globalModal.ConnectWallet?.open()"
      >
        {{ $t("2QEpLv0zhWEMcJ7HK3XD4") }}
      </AButton>

      <ConfirmPayInfo />
    </div>
  </AForm>
</template>

<style scoped lang="scss">
@media bp-lt-tabletl {
  .dapp-pay {
    padding: 0 16px;

    .wallet-address {
      --uno: 'lt-mobilel:px-[15px] lt-mobilel:py-[10px] lt-mobilel:text-xs';

      & > i {
        --uno: 'w-[16px] !mr-[5px]';
      }
    }

    .add-address {
      & > .add-address-content {
        --uno: 'text-base';
      }
    }

    .pay-info {
      .label {
        --uno: ' text-base';

        .unaddress {
          --uno: 'text-base';
        }
      }

      .address {
        --uno: 'text-[#121c41] text-base mt-[15px]';
      }
    }

    .submit {
      --uno: 'px-[0] py-[20px] rounded-[6px]';
    }
  }
}

.pay{
  --uno: 'flex flex-col text-primary bg-bg-secondary p-2.5 pb-2 rounded-lg';

  div{
    --uno: 'flex justify-between text-16px';
  }
}

:deep(.ant-select-selector){
  height: 40px !important;
}

.dapp-pay {
  --uno: 'mt-[20px]';

  .switch-currency-button {
    --uno: 'w-[30px] h-[30px] mx-[10px] my-[20px] text-[#266ef1] cursor-pointer';
  }

  .wallet-address {
    --uno: 'bg-[--bg-one] text-white rounded-[10px] p-[15px] mb-[20px] flex items-center';
  }

  .add-address {
    --uno: 'mx-[0] my-[30px] flex justify-end items-center';

    & > .add-address-content {
      --uno: 'text-[#26a17b] cursor-pointer flex items-center';

      & > :nth-child(1) {
        --uno: 'mr-[5px]';
      }
    }
  }

  .from-currency {
    --uno: 'bg-[--bg-one] rounded-[10px] lt-tablet:p-15px';

    padding: 5px;
    font-size: 16px;

    & > * {
      --uno: 'lt-tablet:p-0';
    }

    .input {
      display: flex;
      align-items: center;

      :deep(.ant-form-item) {
        flex: 1 0 0;
        margin-bottom: 0 !important;

        input {
          font-size: 22px;
        }
      }
    }

    .balance {
      --uno: 'lt-tablet:p-0 lt-tablet:pb-15px lt-tablet:text-[#8098bf] lt-tablet:text-14px';

      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      font-size: 16px;
      color: white;

      & .balance-info {
        --uno: 'lt-tablet:text-12px';

        display: flex;
        align-items: center;

        .ant-spin-blur::after {
          opacity: 0;
        }

        & .balance-info-number {
          margin: 0 5px;
          font-weight: bold;
        }
      }
    }

  }

  .input {
    --uno: 'pr-[15px] pl-[5px] lt-mobilel:p-0';

    .select-currency {
      .currency-item {
        --uno: 'flex items-center text-[white] lt-mobilel:text-14px';
      }

      .select-down-icon {
        color: #c8d0df;
      }
    }

    .input-clear {
      color: #9498a1;
      cursor: pointer;
    }
  }

  .shortcut-select {
    --uno: 'lt-mobilel:pl-[0] lt-mobilel:pr-[0] lt-mobilel:pt-[10px]';

    display: flex;
    justify-content: flex-start;
    padding-top: 15px;

    & > * {
      --uno: 'lt-mobilel:text-[#6f8eca] lt-mobilel:px-[10px] lt-mobilel:py-[5px] lt-mobilel:px-17px lt-mobilel:border-rd-8px text-13px';

      padding: 6px 30px;
      margin-right: 8px;
      font-size: 14px;
      color: var(--secondary-text);
      cursor: pointer;
      border: 1.5px solid rgb(128 152 191 / 50%);
      border-radius: 8px;
      transition: all 0.5s ease-in-out;;
    }

    & > *:hover {
      --uno: 'border-[#266ef1] text-[#266ef1]';
    }
  }

  .to-currency {
    --uno: 'lt-tablet:p-15px';

    padding: 5px;
    background-color: var(--bg-one);
    border-radius: 10px;

    & > * {
      --uno: 'lt-tablet:p-0';
    }

    .input {
      display: flex;
      align-items: center;

      :deep(.ant-form-item) {
        flex: 1 0 0;
        margin-bottom: 0 !important;

        input {
          --uno: 'lt-tablet:text-20px';

          font-size: 22px;
        }
      }
    }

    .balance {
      --uno: 'lt-tablet:p-0 pb-15px text-[#8098bf] text-16px';

      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      font-size: 16px;
      color: white;

      & .balance-info {
        --uno: 'lt-tablet:text-12px';

        display: flex;
        align-items: center;

        .ant-spin-blur::after {
          opacity: 0;
        }

        & .balance-info-number {
          margin: 0 5px;
          font-weight: bold;
        }
      }
    }

    .shortcut-select {
      --uno: 'lt-tablet:px-0 lt-tablet:pt-15px lt-tablet:pb-0';

      display: flex;
      justify-content: flex-start;
      padding: 15px;

      & > * {
        --uno: 'lt-tablet:px-17px lt-tablet:py-7px lt-tablet:border-[1.5px_solid_#6f8eca] lt-tablet:text-[#6f8eca] lt-tablet:text-13px';

        padding: 0 15px;
        margin-right: 8px;
        font-size: 12px;
        color: #26a17b;
        cursor: pointer;
        border: 1px solid #26a17b;
        border-radius: 3px;
        transition: all 0.5s ease-in-out;

      }

      & > *:hover {
        color: white;
        background-color: #26a17b;

        @media bp-lt-tablet {
          background-color: #266ef1;
        }
      }
    }
  }

  .pay-info {
    --uno: 'bg-[#13192e] rounded-[6px] mx-[0] my-[20px] px-[20px] pt-[15px]';

    .label {
      --uno: 'text-[white] flex justify-between items-center text-16px';

      .unaddress {
        --uno: '[transition:all_0.5s] opacity-100 text-[#26a17b] cursor-pointer flex items-center';

        & > :nth-child(1) {
          --uno: 'mr-[5px]';
        }
      }
    }

    :deep(.address) {
      --uno: 'text-[#9cb8ff] mt-[10px]';

      .address-form-item {
        input {
          margin-top: 0;
        }
      }

      & > input {
        --uno: 'pl-[0] pr-[0] py-[15px] focus-visible:border-none focus-visible:bg-none';
      }
    }
  }

  .show-address-enter-active,
  .show-address-leave-active {
    --uno: '[transition:opacity_0.3s_ease]';
  }

  .show-address-enter-from,
  .show-address-leave-to {
    --uno: 'opacity-0';
  }
}

// 修改默认输入框样式
:deep(.ant-input-number) {
  color: var(--text-primary-color);
  background-color: var(--bg-lesser-color);

  input::placeholder{
    font-size: 16px;
    color: #bfbfbf;
    border-radius: 0;
  }
}

:deep(.ant-input) {
  color: var(--text-primary-color);
  background-color: var(--bg-lesser-color);

  :first-child {
    font-size: 22px !important;
    background: unset;
    border-radius: 0 !important;
  }

  &::placeholder{
    font-size: 16px;
    color: #bfbfbf;
  }
}
</style>
